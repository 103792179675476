.registration1 {
  width: 100%;

  .mask_styles {
    margin-left: 12px;
    background-color: transparent;
    font-family: "Brown", serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
    width: 100%;
  }

  &__image_wrapper {
    margin-top: 50px;
    display: flex;
    align-items: center;
  }

  &__image_block {
    position: relative;
    z-index: 1;
    width: 100px;
    height: 100px;
  }

  &__image {
    width: 100px;
    height: 100px;

    border-radius: 50%;
    overflow: hidden;

    &__delete {
      width: 30px;
      height: 30px;
      position: absolute;
      z-index: 2;
      border: 3px solid #24262c;
      border-radius: 50%;
      background: #ff4b55;
      right: -5px;
      top: -5px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  &__upload {
    justify-content: center;
    background: #3b4db3;
    border-radius: 10px;
    padding: 21px 24px;
    margin-left: 30px;
    width: 214px;
    font-family: "Brown", serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    color: #ffffff;
    position: relative;
    z-index: 10;

    input {
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }

  .form {
    width: 100%;
    display: flex;
    margin-top: 50px;

    &__column {
      width: 50%;
      display: flex;
      flex-direction: column;

      &__item {
        width: 100%;
        height: 60px;
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        padding-left: 30px;
        display: flex;
        align-items: center;

        &:hover {
          border: 1px solid rgba(255, 255, 255, 1);
        }

        &__error {
          border: 2px solid red;
          border-radius: 10px;
        }
      }

      &:first-child {
        padding-right: 10px;
      }

      &:not(:first-child) {
        padding-left: 10px;
      }

      & > * {
        margin-bottom: 20px;
      }
    }
  }
  .next {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 60px;

    &__button {
      background: #5900f5;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 133px;
      height: 56px;
      font-family: "Brown", serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
      color: #ffffff;
    }
  }

  input:-webkit-autofill {
    transition: all 20000s ease-in-out !important;
  }

  .formItem {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 100%;
    height: 60px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.05);
  }

  .formInput {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    background: transparent;
    font-weight: 400;
    font-size: 14px;
    padding-left: 30px;
    line-height: 13px;
    color: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;

    &:focus {
      border: 1px solid rgba(255, 255, 255, 1);
    }

    &__input_error {
      border: 1px solid rgb(255, 0, 0);
      border-radius: 10px;
    }
  }

  .formLabel {
    left: 30px;
    position: absolute;
    pointer-events: none;
    top: 23px;
    white-space: nowrap;
    overflow: hidden;
    line-height: 40px;
    transition: 0.3s;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    color: #ffffff;
    opacity: 0.4;
  }

  .formInput:focus ~ .formLabel,
  .formInput:not(:placeholder-shown) ~ .formLabel {
    top: 16px;
    font-weight: 400;
    font-size: 11px;
    line-height: 10px;
  }

  .doubleHeight {
    height: 140px;

    .formInput {
      padding-top: 30px;
    }
  }
}
