.infoContainer {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

.infoText {
  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;

  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.5;
}

.infoLabel {
  display: flex;
  align-items: center;

  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  color: #ffffff;

  opacity: 0.5;
  margin: 0 16px;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.labelContainer > :last-child {
  margin-right: 0;
}

.infoIcon {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin: 0 10px;
}
