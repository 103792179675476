.wrapper {
  width: 100%;
  max-width: 1040px;
  min-height: 740px;
  overflow-y: auto;
  padding: 50px 60px;
  background: #24262c;
  border-radius: 0 50px 50px 0;

  @media screen and (min-width: 1441px) {
    border-radius: 50px;
  }
}

.wrapper_title {
  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 29px;
  color: #ffffff;
}
