.block {
  background: linear-gradient(
    63.82deg,
    rgba(89, 0, 245, 0.7) 4.17%,
    rgba(132, 0, 238, 0.7) 96.17%
  );
  border-radius: 30px;
  height: 210px;
  flex: 1;

  margin: 10px;
  padding: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
}

.blockTitle {
  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #ffffff;

  width: 220px;
}

.blockSubTitle {
  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #c3baf3;
  margin-top: 10px;
}

.imageContainer {
  flex: 1;
}

.blockImage {
  width: 100%;
  height: 100%;
}

.textContainer {
  flex: 2;
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  cursor: pointer;
  user-select: none;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 30px;
}
