.footerText {
  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 11px;
  color: #ffffff;
  opacity: 0.3;
  text-align: center;
  letter-spacing: 1px;

  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
}
