.stats {
  display: flex;
  background: #24262c;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  padding: 5px 5px 5px 20px;
  height: 100%;
  border-radius: 10px;

  .stats_title {
    font-family: "Brown", serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #c3baf3;
  }

  .stats_text {
    font-family: "Brown", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    color: #ffffff;
    margin-left: 10px;
    display: block;
  }
  .stick {
    height: 20px;
    border-left: 1px solid darkgray;
    margin: 0 20px;
  }

  .image_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: #5900f5;
    border-radius: 6px;
    margin-left: 20px;

    &__image {
      width: 14px;
      height: 14px;
    }
  }
}
