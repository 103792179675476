.inner_block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: max-content;

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
}

.header {
  display: block;
  margin-bottom: 5px;
  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 29px;
  text-align: center;
  color: #ffffff;
  max-width: 320px;
}

.text {
  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 10px;
  text-align: center;
  color: #ffffff;
  opacity: 0.5;
  display: block;
}

.mt_12 {
  margin-top: 12px;
}

.mt_40 {
  margin-top: 40px;
}

.mt_60 {
  margin-top: 60px;
}
