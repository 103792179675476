.btn {
  border-radius: 10px;
  padding: 20px 22px;
  border: none;
}

.btnText {
  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: #ffffff;
}
