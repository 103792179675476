.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  max-width: 1600px;
  width: 100%;
  justify-content: space-between;
}

.subTitle {
  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;

  color: #ffffff;

  opacity: 0.5;

  padding: 10px 0;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  margin: 24px 0;
}

.buttonContainer > :first-child {
  margin-left: 0;
}

.buttonActive {
  width: 130px;
  height: 57px;
  margin: 0 5px;
  background: #5900f5;
  border-radius: 10px;
  cursor: pointer;
}

.button {
  cursor: pointer;
  width: 130px;
  height: 57px;
  margin: 0 5px;
  background: rgba(195, 186, 243, 0.2);
  border-radius: 10px;
}

.buttonText {
  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;

  text-align: center;

  color: #ffffff;
  pointer-events: none;
}

.iconButton {
  cursor: pointer;

  width: 56px;
  height: 56px;
  background: #5900f5;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

.levelContainer {
  background: #8400ee;
  border: 5px solid #24262c;
  border-radius: 30px;

  width: 60px;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: 22px;
  bottom: -44px;
}

.levelText {
  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}

.userContainer {
  margin-top: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userName {
  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 29px;
  text-align: center;
  color: #ffffff;
}

.userAddress {
  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  opacity: 0.5;
  margin-top: 10px;
}

.achievementsContainer {
  background: #24262c;
  border-radius: 16px;
  padding: 40px 30px;
  width: 340px;
}

.achievement {
  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
}

.achievementsBlock {
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
}

.achievementValue {
  border-radius: 6px;

  width: 50px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 20px 8px 0;
}

.achievementValue > span {
  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.radarContainer {
  background: #24262c;
  border-radius: 16px;
  padding: 20px;
  width: 340px;
}

.goBackContainer {
  display: flex;
  align-items: center;
}

.goBackIcon {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  width: 30px;
  height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #ffffff;
}

.goBackText {
  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;

  color: #ffffff;
  margin-left: 10px;
}

.userDetailsContainer {
  box-sizing: border-box;
  background: linear-gradient(268.8deg, #2d3037 0%, rgba(45, 48, 55, 0.5) 100%);
  border-radius: 16px;

  height: 180px;
  width: 340px;
  margin-bottom: 12px;
  padding: 20px 30px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}

.closeIcon {
  position: absolute;
  top: 15px;
  right: 2px;
  padding: 15px;
  background: none;
}

.userContactDetails {
  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;

  opacity: 0.5;
}

.userNameDetails {
  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 113.97%;
  color: #ffffff;
  margin-bottom: 8px;
}

.userPosition {
  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 113.97%;
  color: #ffffff;
}

.bottomImage {
  position: absolute;
  right: 0;
  bottom: 16px;
}
