.registration1 {
  width: 100%;
  position: relative;
  .form {
    width: 100%;
    display: flex;
    margin-top: 50px;

    &__column {
      width: 50%;
      display: flex;
      flex-direction: column;

      &:first-child {
        padding-right: 10px;
      }

      &:not(:first-child) {
        padding-left: 10px;
      }

      & > * {
        margin-bottom: 20px;
      }
    }
  }

  .date_row {
    display: flex;

    .date_column {
      width: 50%;
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 10px;
      height: 60px;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;
      box-sizing: content-box;

      &__error {
        border: 2px solid red;
        border-radius: 10px;
      }

      &__icon {
        position: absolute;
        right: 22px;
        z-index: -1;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0.4;
      }

      ::-webkit-calendar-picker-indicator {
        filter: invert(1);
      }

      &:first-child {
        margin-right: 10px;
      }

      &:not(:first-child) {
        margin-left: 10px;
      }

      &__input {
        width: 100%;
        background-color: transparent;
        opacity: 1;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 13px;
        color: #ffffff;
        padding: 20px 23px;

        &:focus {
          border: 1px solid white;
          border-radius: 10px;
        }

        &__item {
          width: 100%;
          background-color: transparent;
        }
      }
    }
  }

  .checkbox {
    height: 60px;
    font-family: "Brown";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    color: #ffffff;
  }

  .skipButton {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 133px;
    height: 56px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #000;
  }

  .next {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 60px;

    &__button {
      background: #5900f5;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 133px;
      height: 56px;
      font-family: "Brown", serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
      color: #ffffff;
    }
  }

  .upper_block {
    position: absolute;
    bottom: calc(100% + 40px);
    display: flex;
    right: 0;

    .linkedIn {
      display: flex;
      width: 220px;
      height: 46px;
      align-items: center;
      padding: 0 25px;
      background: #444454;
      border-radius: 10px;
      justify-content: space-between;
      cursor: pointer;

      &__text {
        font-family: "Brown";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        color: #ffffff;
      }
    }

    .new_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 46px;
      width: 79px;
      top: 160px;
      background: #3b4db3;
      border-radius: 10px;
      font-family: "Brown";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
      color: #ffffff;
      margin-left: 12px;
      cursor: pointer;
    }
  }

  input:-webkit-autofill {
    transition: all 20000s ease-in-out !important;
  }

  .formItem {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 100%;
    height: 60px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.05);
  }

  .formInput {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    background: transparent;
    font-weight: 400;
    font-size: 14px;
    padding-left: 30px;
    line-height: 13px;
    color: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;

    &:focus {
      border: 1px solid rgba(255, 255, 255, 1);
    }
    &__error {
      border: 2px solid red;
      border-radius: 10px;
    }
  }

  .formLabel {
    left: 30px;
    position: absolute;
    pointer-events: none;
    top: 23px;
    white-space: nowrap;
    overflow: hidden;
    line-height: 40px;
    transition: 0.3s;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    color: #ffffff;
    opacity: 0.4;
  }

  .formInput:focus ~ .formLabel,
  .formInput:not(:placeholder-shown) ~ .formLabel {
    top: 16px;
    font-weight: 400;
    font-size: 11px;
    line-height: 10px;
  }

  .doubleHeight {
    height: 140px;

    .formInput {
      padding-top: 30px;
    }
  }
}
