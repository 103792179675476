.container {
  width: 100%;
  height: 100vh;
  opacity: 1;
  display: flex;
  animation: fadeIn ease 1s;
  overflow: hidden;
}

.animationBlock {
  width: 100%;
  max-width: 120px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}

.coloredContainer {
  position: relative;
  width: calc(100% - 120px);
  height: 100vh;
}

.logo {
  padding-top: 56px;
  padding-bottom: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rectangleBlock {
  box-sizing: border-box;

  border-radius: 50px;
  margin: 0 auto;
  padding: 90px 100px;
  height: 620px;
  max-width: 740px;
}

.titleBlock {
  margin-bottom: 30px;
}

.title {
  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 29px;
  letter-spacing: 2px;
  margin-bottom: 40px;
}

.form {
  display: flex;
  flex-direction: column;
}

.input {
  border: 1px solid rgba(24, 25, 29, 0.1);
  border-radius: 10px;
  height: 60px;
  padding: 0 32px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
}

.inputError {
  border: 1px solid rgba(255, 0, 0, 0.5) !important;
}

.input::placeholder {
  font-family: "Brown", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  /*color: #18191d;*/
  /*#FFFFFF*/
  opacity: 0.4;
}

.buttonBlock {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
}

.haveAccount {
  /*margin-left: 30px;*/

  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  text-align: center;
  color: #18191d;
  letter-spacing: 1px;
}

.link {
  color: #610df6;
  font-weight: bolder;
  text-decoration: underline;
  cursor: pointer;
  text-underline-offset: 2px;
}

.textAnotherVariant {
  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  letter-spacing: 1px;
  opacity: 0.4;

  margin-bottom: 20px;
  margin-top: 10px;
}

.mediaBlock {
  margin-top: 40px;
}

.mediaButtons {
  display: flex;
  flex-direction: row;
  align-self: center;
}

.errorLabel {
  display: inline-block;
  text-align: left;
  color: rgba(255, 0, 0, 0.5);
  text-transform: uppercase;
  font-size: 10px;
  margin-top: 2px;
  margin-bottom: 5px;
}

.willHide {
  opacity: 1;
  transition: 0.3s;
}

.hide {
  opacity: 0;
}

.willSlideRight {
  right: 0;
  transition: 0.2s;
  transition-delay: 0.3s;
}

.willSlideLeft {
  left: 0;
  transition: 0.2s;
  transition-delay: 0.3s;
}

.slideRight {
  right: -43%;
}

.slideLeft {
  left: -43%;
}
