.progress_wrapper {
  width: 150px;
  height: 150px;
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 13px solid #24262c;
    border-radius: 50%;
    z-index: -1;
  }
}
