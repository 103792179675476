.main {
  display: flex;
  width: 100%;
  justify-content: center;
  background: #18191d;
  margin-top: 50px;

  &__block {
    width: 100%;
    max-width: 1660px;
    display: flex;
    justify-content: space-between;
  }
}
