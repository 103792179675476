.header {
  display: flex;
  justify-content: center;
  padding: 30px 24px;
  background: #18191d;

  &__container {
    display: flex;
    width: 100%;
    max-width: 1600px;
    justify-content: space-between;
  }

  .stick {
    height: 20px;
    border-left: 1px solid darkgray;
    margin: 0 20px;
  }

  .icons {
    display: flex;
    align-items: center;

    &__block {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #24262c;
      position: relative;
      z-index: 15;
      cursor: pointer;

      &:not(:first-child) {
        margin-left: 10px;
      }

      &__amount {
        position: absolute;
        width: 14px;
        height: 14px;
        left: 26px;
        top: -3px;
        background: #ff4b55;
        border-radius: 50%;
        z-index: 16;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          font-family: "Brown", serif;
          font-style: normal;
          font-weight: 700;
          font-size: 8px;
          line-height: 8px;
          text-align: center;
          color: #ffffff;
        }
      }
    }
  }
}
