.btn {
  border-radius: 10px;
  padding: 20px 32px;
  border: none;
  cursor: pointer;
}

.btnText {
  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
}
