.sidebar {
  width: 100%;
  max-width: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  height: 100%;
  max-height: 100%;
  margin-left: 14px;
  margin-right: 30px;
}
