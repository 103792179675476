.drop_file_input {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
  border: 1px dashed rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding-top: 36px;
  padding-bottom: 36px;

  &__label {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Brown", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    color: #ffffff;
    opacity: 0.4;

    img {
      margin-bottom: 13px;
    }
  }

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.drop-file-preview {
  display: flex;
  height: 80px;
  justify-content: center;
}

.drop-file-preview p {
  font-weight: 400;
  max-width: 130px;
  overflow: hidden;
}

.drop-file-preview__title {
  margin-bottom: 20px;
}

.drop-file-preview__item {
  position: relative;
  display: flex;
  border-radius: 20px;
  background-color: white;
  padding: 10px 10px;
  border: 2px solid orange;
  width: 50%;
}

.drop-file-preview__item img {
  height: 100%;
}

.drop-file-preview__item__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 19px;
  overflow: hidden;
}

.drop-file-preview__item__del {
  background-color: orange;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -5px;
  top: 0;
  cursor: pointer;
}
