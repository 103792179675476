.mainContainer {
  display: flex;
  position: relative;
  max-height: 100vh;
  height: 100vh;
  transition: 0.3s;
  transition-delay: 0.2s;
  left: 0;
  width: 100%;
}

.leftBlockWrapper {
  background: linear-gradient(63.82deg, #5900f5 4.17%, #8400ee 96.17%);
  width: 100%;
  overflow: hidden;
}

.leftBlock {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: 0.5s;
}

.rightBlock {
  position: relative;
  width: 100%;
  background: #18191d;
  opacity: 1;
  transition: 0.5s;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 58px;
  margin-bottom: 24px;
}

.descriptionLeft {
  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  opacity: 0.8;
  max-width: 504px;
  margin: 0 auto;
  padding: 12px 0;
  letter-spacing: 0.5px;
}

.descriptionRight {
  font-family: "Brown", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  opacity: 0.8;
  margin: 0 auto;
  max-width: 504px;
  padding: 12px 0;
  letter-spacing: 1px;
}

.link {
  font-family: "Brown", serif;
  font-style: normal;
  font-size: 14px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  letter-spacing: 0.5px;
}

.bottomImageLeft {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.bottomImageRight {
  position: absolute;
  bottom: 0;
  right: 20px;
  left: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

@media screen and (max-height: 899px) {
  .bottomImageLeft {
    bottom: -100px;
  }
  .bottomImageRight {
    bottom: -50px;
  }
}

.bottomImage > img {
  height: 100%;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.text_wrapper {
  height: 337px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.slide-left {
  position: relative;
  left: -42%;
  transition: 0.5s;
}

.slide-right {
  width: 4000px;
  transition: 0.5s;
  transition-delay: 0.2s;
}

.slide-left .rightBlock {
  opacity: 0;
}

.slide-right .leftBlock {
  opacity: 0;
}
