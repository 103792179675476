@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap");

* {
  font-family: "Cabin", sans-serif !important;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  background: #18191d;
  overflow-x: hidden;
}

button,
select,
input,
button:focus,
input:focus,
select:focus,
button:active,
input:active,
select:active {
  outline: none;
  border: none;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
}
