.profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  min-width: 100px;
  .profile_block {
    position: relative;
    width: 40px;
    height: 40px;
    z-index: 10;

    &__image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }

    &__circle {
      position: absolute;
      z-index: 1;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 2px solid #24262c;
      top: 0;
    }
  }

  &__name {
    font-family: "Brown", serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
    max-width: 50px;
    overflow: hidden;
  }
}
